<template>
    <div>
    <b-card title="Informations">
    
      <b-row>
        <b-col
          md="6"
          xl="4"
          class="mb-1"
        >

        <b>Nom :</b> {{ beta.name }}<br /><br />
        <b>Prénom :</b> {{ beta.surname }}<br /><br />
        <b>Email :</b> {{ beta.name }}<br /><br />
        <b>Message :</b> {{ beta.message }}<br /><br />
        <b>Status :</b> {{ beta.status }}<br /><br />
        </b-col>
      </b-row>

    </b-card>

  <b-button
     @click="acceptBeta" 
    >
    Accepter
    </b-button>

  </div>
</template>

<script>
import {BModal, BButton, VBModal, BCard, BCardText, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, } from 'bootstrap-vue'
import {APIRequest} from '@simb2s/senseye-sdk'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BCardText,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BModal,
    BButton,
    ToastificationContent
  },
  data() {
    return {
      loaded:false,
      beta:null
    }
  },
  methods:{
    async acceptBeta(){
    this.loading=true
    let parameters={status:'ok'}
    await APIRequest.update('beta-testers', this.$route.params.id, parameters)
    this.$toast({
      component: ToastificationContent,
      props: {
        title: 'Le beta testeur a bien été accepté !',
        icon: 'CheckIcon',
        variant: 'success',
      },
    })
    this.$router.push({name:'betas'})
   }
  },
  async mounted()
  {
    this.beta=await APIRequest.detail('beta-testers',this.$route.params.id)
    this.loaded=true
  }
}
</script>
